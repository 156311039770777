<template>
  <div class="container" @keypress.enter="setupPassword()">
    <div class="title">
      <span>Xác thực thành công</span>
    </div>
    <div class="sub-title"></div>

    <label>Nhập mật khẩu mới</label>
    <input-view
        :iconLeft="require('@/assets/icons/lock.svg')"
        :iconRight="EyeNone"
        label="Mật khẩu gồm 8-32 ký tự"
        type="password"
        name="password"
        ref="passwordRef"
        @input="password = $event"
    ></input-view>

    <div class="form-group"></div>

    <div class="form-group mb-0">
      <button class="btn btn-primary mt-0">Đổi mật khẩu</button>
    </div>
    <div style="min-height: 30px"></div>
  </div>
</template>
<script>
import InputView from "../../components/InputView.vue";
import EyeNone from "@/assets/icons/eye-none.svg";
import EyeOutline from "@/assets/icons/eye-outline.svg";
import Util from "@/util";

export default {
  components: { InputView },
  data() {
    return {
      EyeNone,
      EyeOutline,
      password: null,
      passwordConfirm: null,

      errorMessage: null,
      error: {
        message: null,
        validates: [],
      },
    };
  },
  provide() {
    return { error: this.error };
  },
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.verifyToken(params.token);
  },
  methods: {
    async verifyToken(token) {
      this.$store.dispatch("loading", true);
      try {
        await this.$store.dispatch("auth/confirmForgotPassword", {
          token,
        });
      } catch (e) {
        if (e.error) {
          if (e.error.code == 404) {
            //
            this.$store.dispatch("showAlert", {
              type: "error",
              primaryText: 'Trang chủ',
              message: e.error.message,
              actionPrimary: () => {
                this.$store.dispatch("hideAlert");
                location.href = process.env.VUE_APP_MEEY_LAND
              },
            });
          }
        } else {
          Util.errorHandler(this, e);
        }
      } finally {
        this.$store.dispatch("loading", false);
      }
    },
  },
};
</script>
<style scoped lang='scss'>
</style>